import { DATA_TYPE, DISPLAY_UNIT, SFOC_OR_LOAD, UTC_TIMESTAMP, VIEW_BY_TYPE } from '@constants';
import { getValue } from '@helpers';
import { getDisplayUnit } from '@store/features';
import { getBaselineComparisonSlice } from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import moment from 'moment/moment';

import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDisplayUnit } from './DisplayUnit';

export const useBaselineComp = () => {
  const displayUnit = useSelector(getDisplayUnit);
  const { getUnitTitle, convertUnitValue } = useDisplayUnit();
  const { startDate, endDate } = useSelector(getMainPageSlice);
  const {
    selectedBaseline,
    equipment,
    analytics,
    powerMgmt,
    periodicOverview,
    drillingActivities
  } = useSelector(getBaselineComparisonSlice);

  const { start, end } = useMemo(() => {
    const { startDate: bStart, endDate: bEnd } = selectedBaseline;
    let start = moment(startDate);
    let baseDuration = moment
      .duration(moment.parseZone(bEnd).startOf('day').diff(moment.parseZone(bStart)))
      .asDays();
    let mainDuration = moment
      .duration(moment.parseZone(endDate).diff(moment.parseZone(startDate)))
      .asDays();
    let duration = baseDuration > mainDuration ? baseDuration : mainDuration;
    let end = moment(startDate).endOf('day').add(duration, 'days');
    return { start: start, end: end, duration: duration };
  }, [selectedBaseline, startDate, endDate]);
  const getBaselineObj = (viewByUnit) => {
    let base = {};
    let baseStart = moment.parseZone(selectedBaseline.startDate).startOf(viewByUnit);
    let baseEnd = moment.parseZone(selectedBaseline.endDate);
    while (baseStart.isSameOrBefore(baseEnd)) {
      let dayKey = baseStart.clone().format(UTC_TIMESTAMP);
      if (base[dayKey] === undefined) {
        base[dayKey] = 0;
      }
      baseStart.add(1, viewByUnit);
    }
    return base;
  };

  const getResult = (main, base, displayUnit, dataType) => {
    let resMain = [];
    let resBase = [];
    let baselineData = Object.keys(base)
      .sort()
      .map((key) => base[key]);
    let i = 0;
    let mainNext = 0;
    let baseNext = 0;
    let max = 0;
    for (const [key, value] of Object.entries(main)) {
      if (dataType === DATA_TYPE.ACCUMULATED) {
        mainNext += value;
        baseNext += baselineData[i] ?? 0;
      } else {
        mainNext = value;
        baseNext = baselineData[i];
      }
      max = max < mainNext ? mainNext : max;
      max = max < baseNext ? baseNext : max;
      resMain.push({ x: key, y: mainNext });
      resBase.push({ x: key, y: baseNext });
      i++;
    }
    const { title, converted } = getUnitTitle(max, displayUnit);
    if (converted) {
      resBase = resBase.map(({ x, y }) => {
        if (y !== null) {
          return { x: x, y: parseFloat(convertUnitValue(y, displayUnit, false)) };
        }
        return { x: x, y: y };
      });
      resMain = resMain.map(({ x, y }) => {
        if (y !== null) {
          return { x: x, y: parseFloat(convertUnitValue(y, displayUnit, false)) };
        }
        return { x: x, y: y };
      });
    }
    return {
      mainSeries: resMain.sort((a, b) => moment.utc(a.x).valueOf() - moment.utc(b.x).valueOf()),
      baseSeries: resBase.sort((a, b) => moment.utc(a.x).valueOf() - moment.utc(b.x).valueOf()),
      unit: title,
      max: converted ? parseFloat(convertUnitValue(max, displayUnit)) : max
    };
  };

  const getPeriodicOverviewSeries = useCallback(
    (mainRigData, baselineData) => {
      const { operation, dataType, viewBy } = periodicOverview;
      let cat = [];
      let main = {};
      let base = {};
      let viewByUnit = viewBy === VIEW_BY_TYPE.DAILY ? 'day' : 'hour';
      let startClone = start.clone();
      while (startClone.isSameOrBefore(end)) {
        let dayKey = startClone.clone().format(UTC_TIMESTAMP);
        cat.push(dayKey);
        main[dayKey] = 0;
        startClone.add(1, viewByUnit);
      }
      operation.forEach(({ key }) => {
        let typeKey = `${key}${displayUnit}`;
        Object.entries(mainRigData[typeKey] ?? {}).forEach(([key, value]) => {
          let dayKey = moment.parseZone(key).startOf(viewByUnit).format(UTC_TIMESTAMP);
          main[dayKey] += getValue(value);
        });

        Object.entries(baselineData[typeKey] ?? {}).forEach(([key, value]) => {
          let dayKey = moment.parseZone(key).startOf(viewByUnit).format(UTC_TIMESTAMP);
          if (base[dayKey] === undefined) {
            base[dayKey] = 0;
          }
          base[dayKey] += getValue(value);
        });
      });
      return { ...getResult(main, base, displayUnit, dataType), categories: cat };
    },
    [displayUnit, selectedBaseline, startDate, endDate, periodicOverview]
  );

  const getEquipmentSeries = useCallback(
    (mainRigData, baselineData) => {
      const { dataType, equipmentType, viewBy } = equipment;
      let cat = [];
      let main = {};
      let base = {};
      let viewByUnit = viewBy === VIEW_BY_TYPE.DAILY ? 'day' : 'hour';

      let startClone = start.clone();
      while (startClone.isSameOrBefore(end)) {
        let dayKey = startClone.clone().format(UTC_TIMESTAMP);
        cat.push(dayKey);
        main[dayKey] = 0;
        startClone.add(1, viewByUnit);
      }
      equipmentType.forEach(({ key }) => {
        let typeKey = `${key}${displayUnit}`;

        Object.entries(mainRigData[typeKey] ?? {}).forEach(([key, value]) => {
          let dayKey = moment.parseZone(key).startOf(viewByUnit).format(UTC_TIMESTAMP);
          main[dayKey] += getValue(value);
        });

        Object.entries(baselineData[typeKey] ?? {}).forEach(([key, value]) => {
          let dayKey = moment.parseZone(key).startOf(viewByUnit).format(UTC_TIMESTAMP);
          if (base[dayKey] === undefined) {
            base[dayKey] = 0;
          }
          base[dayKey] += getValue(value);
        });
      });

      return { ...getResult(main, base, displayUnit, dataType), categories: cat };
    },
    [displayUnit, selectedBaseline, start, end, equipment]
  );

  const getDrillingSeries = useCallback(
    (mainRigData, baselineData) => {
      const { dataType, activityType, viewBy } = drillingActivities;
      let cat = [];
      let main = {};
      let viewByUnit = viewBy === VIEW_BY_TYPE.DAILY ? 'day' : 'hour';
      let base = getBaselineObj(viewByUnit);

      let startClone = start.clone();
      while (startClone.isSameOrBefore(end)) {
        let dayKey = startClone.clone().format(UTC_TIMESTAMP);
        cat.push(dayKey);
        main[dayKey] = 0;
        startClone.add(1, viewByUnit);
      }
      const key = `drilling${displayUnit}`;
      activityType?.forEach(({ name }) => {
        let mainData = mainRigData.find(
          ({ activity }) => name.toLowerCase() === activity.toLowerCase()
        );
        let baseData = baselineData.find(
          ({ activity }) => name.toLowerCase() === activity.toLowerCase()
        );

        if (mainData) {
          Object.entries(mainData.drillingActivityEvents[key] ?? {}).forEach(([key, value]) => {
            let dayKey = moment.parseZone(key).startOf(viewByUnit).format(UTC_TIMESTAMP);
            if (main[dayKey] === undefined) {
              main[dayKey] = 0;
            }
            main[dayKey] += getValue(value);
          });
        }
        if (baseData) {
          Object.entries(baseData.drillingActivityEvents[key] ?? {}).forEach(([key, value]) => {
            let dayKey = moment.parseZone(key).startOf(viewByUnit).format(UTC_TIMESTAMP);
            if (base[dayKey] === undefined) {
              base[dayKey] = 0;
            }
            base[dayKey] += getValue(value);
          });
        }
      });
      console.log(main, base);

      return { ...getResult(main, base, displayUnit, dataType), categories: cat };
    },
    [displayUnit, selectedBaseline, start, end, drillingActivities]
  );
  const convertAnalyticsData = (data, valueKey, focusAreaKeys, viewBy, resRef) => {
    const res = { ...resRef };
    data.forEach((obj) => {
      if (!focusAreaKeys.includes(obj.type)) return;
      let eventStart = moment.parseZone(obj.startDate);
      let eventEnd = moment.parseZone(obj.endDate);
      let eventDuration = moment.duration(eventEnd.diff(eventStart)).asMinutes();
      let eventValue = obj[valueKey];
      let eventMinuteValue = eventValue / eventDuration;
      let viewByUnit = viewBy === VIEW_BY_TYPE.DAILY ? 'day' : 'hour';
      if (eventStart.isSame(eventEnd, viewByUnit)) {
        // If startDate and endDate is in the same unit. i.e same hour or day
        let timeKey = eventStart.startOf(viewByUnit).format(UTC_TIMESTAMP);
        if (res[timeKey] === undefined) {
          res[timeKey] = 0;
        }
        res[timeKey] += getValue(eventValue);
      } else {
        let startClone = eventStart.clone();
        while (startClone.isSameOrBefore(eventEnd)) {
          let nextTime = startClone.clone().startOf(viewByUnit).add(1, viewByUnit);
          if (nextTime.isAfter(eventEnd)) {
            nextTime = eventEnd.clone();
          }
          let timeKey = startClone.clone().startOf(viewByUnit).format(UTC_TIMESTAMP);
          let currDuration = moment.duration(nextTime.diff(startClone)).asMinutes();

          if (res[timeKey] === undefined) {
            res[timeKey] = 0;
          }
          res[timeKey] += eventMinuteValue * currDuration;
          startClone = nextTime.clone();
          if (nextTime.isSame(eventEnd)) {
            break;
          }
        }
      }
    });
    return res;
  };

  const getAnalyticsSeries = useCallback(
    (mainRigData, baselineData) => {
      const { dataType, focusArea, viewBy } = analytics;
      let cat = [];
      let main = {};
      let viewByUnit = viewBy === VIEW_BY_TYPE.DAILY ? 'day' : 'hour';
      let base = getBaselineObj(viewByUnit);
      let startClone = start.clone();
      while (startClone.isSameOrBefore(end)) {
        let dayKey = startClone.clone().format(UTC_TIMESTAMP);
        cat.push(dayKey);
        main[dayKey] = 0;
        startClone.add(1, viewByUnit);
      }
      let valueKey = `${displayUnit.toLowerCase()}Saving`;
      let focusAreaKeys = focusArea?.map(({ key }) => key);
      main = convertAnalyticsData(mainRigData, valueKey, focusAreaKeys, viewBy, main);
      base = convertAnalyticsData(baselineData, valueKey, focusAreaKeys, viewBy, base);
      return { ...getResult(main, base, displayUnit, dataType), categories: cat };
    },
    [displayUnit, selectedBaseline, start, end, analytics]
  );

  const getPowerMgmtSeries = useCallback(
    (mainRigData, baselineData) => {
      const { viewBy, dataUnit } = powerMgmt;
      let cat = [];
      let main = {};
      let mainEngOn = {};
      let base = {};
      let baseEngOn = {};
      let viewByUnit = viewBy === VIEW_BY_TYPE.DAILY ? 'day' : 'hour';
      const { numOfEngines: mainNumEng, clarifyData: mainClarifyData } = mainRigData;
      const { numOfEngines: secNumEng, clarifyData: secClarifyData } = baselineData;

      let startClone = start.clone();
      while (startClone.isSameOrBefore(end)) {
        let dayKey = startClone.clone().format(UTC_TIMESTAMP);
        main[dayKey] = null;
        base[dayKey] = null;
        mainEngOn[dayKey] = 0;
        baseEngOn[dayKey] = { count: 0, value: 0 };
        cat.push(dayKey);
        startClone.add(1, viewByUnit);
      }
      console.log(main);
      Object.entries(mainClarifyData['EngOn']).forEach(([key, value]) => {
        let dayKey = moment.parseZone(key).startOf(viewByUnit).format(UTC_TIMESTAMP);
        mainEngOn[dayKey] += getValue(value);
      });
      mainEngOn = Object.keys(mainEngOn).map((key) => {
        let val = mainEngOn[key];
        if (viewBy === VIEW_BY_TYPE.DAILY) {
          val = val / 24;
        }
        return { x: key, y: val };
      });
      Object.values(secClarifyData['EngOn']).forEach((value, i) => {
        let index = i;
        if (viewBy === VIEW_BY_TYPE.DAILY) {
          index = Math.floor(i / 24);
        }
        let dayKey = moment.utc(cat[index]).startOf(viewByUnit).format(UTC_TIMESTAMP);
        let count = isNaN(value) ? 0 : 1;
        baseEngOn[dayKey] = {
          count: baseEngOn[dayKey].count + count,
          value: baseEngOn[dayKey].value + getValue(value)
        };
      });
      baseEngOn = Object.keys(baseEngOn).map((key) => {
        let val = baseEngOn[key].value;
        if (viewBy === VIEW_BY_TYPE.DAILY) {
          return { x: key, y: val / (baseEngOn[key].count || 1) };
        }
        return { x: key, y: val };
      });

      let mainEngDayCount = {};
      for (let i = 1; i <= mainNumEng; i++) {
        let tempEngDayCount = {};
        let tempMain = {};
        let objKey = `Eng${i}${dataUnit === SFOC_OR_LOAD.SFOC ? 'SFOC' : 'Lavg'}`;
        Object.entries(mainClarifyData[objKey]).forEach(([key, value]) => {
          let dayKey = moment.parseZone(key).startOf(viewByUnit).format(UTC_TIMESTAMP);
          if (value !== 'null' && value !== null) {
            tempEngDayCount[dayKey] = true;
            tempMain[dayKey] = {
              count: (tempMain[dayKey]?.count ?? 0) + 1,
              value: (tempMain[dayKey]?.value ?? 0) + getValue(value)
            };
          }
        });
        Object.keys(tempEngDayCount).forEach((key) => {
          mainEngDayCount[key] = (mainEngDayCount[key] ?? 0) + 1;
        });

        Object.keys(tempMain).forEach((key) => {
          let val = tempMain[key].value;
          let count = tempMain[key]?.count || 1;

          if (viewBy === VIEW_BY_TYPE.DAILY) {
            main[key] += val / count;
          } else {
            main[key] += val;
          }
        });
      }
      let baseEngDayCount = {};
      for (let i = 1; i <= secNumEng; i++) {
        let tempEngDayCount = {};
        let tempBase = {};
        let objKey = `Eng${i}${dataUnit === SFOC_OR_LOAD.SFOC ? 'SFOC' : 'Lavg'}`;
        Object.values(secClarifyData[objKey]).forEach((value, i) => {
          let index = i;
          if (viewBy === VIEW_BY_TYPE.DAILY) {
            index = Math.floor(i / 24);
          }

          let dayKey = moment.utc(cat[index]).startOf(viewByUnit).format(UTC_TIMESTAMP);
          if (value !== 'null' && value !== null) {
            tempEngDayCount[dayKey] = true;
            tempBase[dayKey] = {
              count: (tempBase[dayKey]?.count ?? 0) + 1,
              value: (tempBase[dayKey]?.value ?? 0) + getValue(value)
            };
          }
        });
        Object.keys(tempEngDayCount).forEach((key) => {
          baseEngDayCount[key] = (baseEngDayCount[key] ?? 0) + 1;
        });
        Object.keys(tempBase).forEach((key) => {
          let val = tempBase[key].value;
          if (viewBy === VIEW_BY_TYPE.DAILY) {
            val = val / (tempBase[key]?.count || 1);
          }
          base[key] += val;
        });
      }
      Object.keys(main).forEach((key) => {
        if (main[key] !== null) {
          main[key] = main[key] / mainEngDayCount[key];
        }
      });
      Object.keys({ ...base }).forEach((key) => {
        if (base[key] !== null) {
          base[key] = base[key] / baseEngDayCount[key];
        }
      });
      mainEngOn = mainEngOn.sort((a, b) => moment.utc(a.x).valueOf() - moment.utc(b.x).valueOf());
      baseEngOn = baseEngOn.sort((a, b) => moment.utc(a.x).valueOf() - moment.utc(b.x).valueOf());
      let secMax = secNumEng > mainNumEng ? secNumEng : mainNumEng;
      return {
        ...getResult(main, base, DISPLAY_UNIT.ENERGY, DATA_TYPE.DISCRETE),
        mainEngOnSeries: mainEngOn,
        baseEngOnSeries: baseEngOn,
        secMax: secMax
      };
    },
    [selectedBaseline, start, end, powerMgmt]
  );

  return {
    getPeriodicOverviewSeries,
    getDrillingSeries,
    getEquipmentSeries,
    getAnalyticsSeries,
    getPowerMgmtSeries
  };
};
