import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  Button,
  Grid,
  Skeleton,
  Stack,
  Typography
} from '@esgian/esgianui';
import { getCustomUTC } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

function LogPeriodDisplay({ loading, startDate, endDate, setLogEnd, setLogStart, unit }) {
  const selectedUTC = useSelector(getCustomUTC);
  const periodDisplay = useMemo(() => {
    if (!startDate || !endDate) return '';
    if (unit === 'hour') {
      return `${startDate?.format('HH:mm')} - ${endDate?.format('HH:mm')}`;
    }
    return startDate.format('DD/MMM/YYYY');
  }, [unit, startDate, endDate]);

  const currentTime = useMemo(() => {
    const timezone = selectedUTC?.value || null;
    const momentCustomDateTime = moment().tz(timezone)?.startOf('day');
    return momentCustomDateTime;
  }, []);

  const selectedTime = useMemo(() => {
    return moment.tz(startDate, selectedUTC?.value)?.startOf('day');
  }, [startDate]);

  return (
    <Grid item xs={12}>
      {unit !== 'custom' && (
        <Stack
          alignItems={'center'}
          direction={'row'}
          sx={{ pl: 2, pr: 2, pt: 1 }}
          justifyContent={'space-between'}>
          <Button
            disabled={loading || unit !== 'day'}
            onClick={() => {
              setLogEnd((prev) => prev.clone().subtract(1, unit));
              setLogStart((prev) => prev.clone().subtract(1, unit));
            }}
            startIcon={<ArrowBackIcon />}
            size={'small'}
            variant={'text'}>
            Prev
          </Button>
          <Typography variant={'body2'}>
            {loading ? <Skeleton width={'6.5em'} /> : selectedTime?.format('DD/MMM/YYYY')}
          </Typography>
          <Button
            disabled={loading || unit !== 'day' || selectedTime.isSameOrAfter(currentTime, 'day')}
            onClick={() => {
              setLogEnd((prev) => prev.clone().add(1, unit));
              setLogStart((prev) => prev.clone().add(1, unit));
            }}
            endIcon={<ArrowForwardIcon />}
            size={'small'}
            variant={'text'}>
            Next
          </Button>
        </Stack>
      )}
      {unit === 'hour' && (
        <Stack
          alignItems={'center'}
          direction={'row'}
          sx={{ pl: 2, pr: 2, pt: 1 }}
          justifyContent={'space-between'}>
          <Button
            disabled={loading}
            onClick={() => {
              setLogEnd((prev) => prev.clone().subtract(1, unit));
              setLogStart((prev) => prev.clone().subtract(1, unit));
            }}
            startIcon={<ArrowBackIcon />}
            size={'small'}
            variant={'text'}>
            Prev
          </Button>
          <Typography variant={'body2'}>
            {loading ? <Skeleton width={'6.5em'} /> : periodDisplay}
          </Typography>
          <Button
            disabled={loading}
            onClick={() => {
              setLogEnd((prev) => prev.clone().add(1, unit));
              setLogStart((prev) => prev.clone().add(1, unit));
            }}
            endIcon={<ArrowForwardIcon />}
            size={'small'}
            variant={'text'}>
            Next
          </Button>
        </Stack>
      )}
      {unit === 'custom' && (
        <Stack spacing={2} sx={{ pl: 2, pr: 2, pt: 1 }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body2'}>Start date</Typography>
            <Typography variant={'body2'}>{startDate?.format('DD/MMM/YYYY HH:mm')}</Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body2'}>End date</Typography>
            <Typography variant={'body2'}>{endDate?.format('DD/MMM/YYYY HH:mm')}</Typography>
          </Stack>
        </Stack>
      )}
    </Grid>
  );
}

LogPeriodDisplay.propTypes = {
  unit: PropTypes.oneOf(['hour', 'day', 'custom']),
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  loading: PropTypes.bool,
  setLogEnd: PropTypes.func.isRequired,
  setLogStart: PropTypes.func.isRequired
};

LogPeriodDisplay.defaultProps = {
  unit: 'day',
  startDate: null,
  endDate: null,
  loading: false
};

export default LogPeriodDisplay;
