import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';
import moment from 'moment-timezone';
import { useTheme } from '@hooks';

function WellSelect({ loading, selectedWell, lookupWells, onChange }) {
  const { customScrollbar } = useTheme();
  const handleChange = useCallback(
    ({ target }) => {
      if (target.value === 'none') {
        selectedWell(null);
      } else {
        let index = parseInt(target.value);
        let well = lookupWells[parseInt(target.value)];
        if (well) {
          onChange({ ...well, index: index });
        }
      }
    },
    [lookupWells]
  );

  return (
    <Stack>
      <Typography variant={'body2'}>Well</Typography>
      <FormControl sx={{ minWidth: 240 }} size={'small'}>
        <TextField
          disabled={loading}
          SelectProps={{
            MenuProps: {
              slotProps: { paper: { sx: { ...customScrollbar } } },
              sx: { ...customScrollbar, maxHeight: '40vh' }
            }
          }}
          size={'small'}
          select
          label={loading ? <CircularProgress size={25} /> : ''}
          value={!loading ? selectedWell?.index ?? 'none' : ''}
          variant={'outlined'}
          onChange={handleChange}>
          <MenuItem value={'none'}>{'-'}</MenuItem>
          {lookupWells?.map(({ wellname, starttime, endtime }, i) => (
            <MenuItem key={`rig-well-${i}`} value={i}>
              <Stack>
                <Typography variant={'body2'}> {wellname}</Typography>
                <Typography color={'text.secondary'} variant={'caption'}>
                  {`${moment.parseZone(starttime).format('DD/MMM/YYYY')} - ${moment
                    .parseZone(endtime)
                    .format('DD/MMM/YYYY')}`}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Stack>
  );
}

WellSelect.propTypes = {
  loading: PropTypes.bool,
  selectedWell: PropTypes.object,
  lookupWells: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired
};

WellSelect.defaultProps = {
  loading: false,
  selectedWell: null,
  lookupWells: []
};

export default WellSelect;
